.radio-widget {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.radio-widget .radio-button {
  margin: 0.5rem 0;
}

.radio-widget .radio-button label {
  padding: 0.25rem;
}
