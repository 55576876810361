.footer-top {
  &.ui.segment {
    position: relative;
    padding-top: 30px;
    border: unset !important;
  }

  .social-icons-list {
    display: inline-block;
    list-style: none;

    li {
      display: inline-block !important;
    }
  }

  .social-icons {
    width: 22px;
    height: 22px;
    color: #003f7d;
    text-align: left;

    &.twitter-logo {
      width: 20px;
      height: 20px;
    }
  }

  .go-to-top {
    position: absolute;
    top: -45px;
    right: 22px;
    width: 100px;

    svg {
      padding-right: 9px;
    }
  }
}
